import React, { useMemo, FC,  useCallback, useState, useEffect, useRef } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { 
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton  
} from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SlopeWalletAdapter,
  TorusWalletAdapter,
  
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl, sendAndConfirmTransaction } from "@solana/web3.js";
import * as buffer from "buffer";
import { WalletNotConnectedError, WalletSendTransactionError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Keypair, SystemProgram, Transaction, PublicKey } from '@solana/web3.js';
import { createTransferInstruction, getAccount, Account, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount, createMint, getMint, createAssociatedTokenAccountInstruction ,ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as bs58 from "bs58";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'chart.js/auto';
import './style.css';
import './reset.css';
import { serialize } from "v8";
window.Buffer = buffer.Buffer;

require('@solana/wallet-adapter-react-ui/styles.css');

//const systemPublicKey = new PublicKey("9TuHVFoi7eD6RSQFZ4LC7UQ8Svrsrqia27cvairj5D7B");


let walletName = "";
let gTokenId = "SOL";
let userTokenPub : Account;
let bankTokenPub : Account;
let strTokenReady = "NOTSET";
const isDev = false;

//const [ frameMessage , setFrameMessage ] = useState("");




const App = () => {

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  //const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const endpoint = "https://chaotic-responsive-diamond.solana-mainnet.quiknode.pro/a110f8f9398583424c108cf1f87ecaaf71ffe7ea";

  const wallets = useMemo(
      () => [
          new PhantomWalletAdapter(),
          new SlopeWalletAdapter(),
          new SolflareWalletAdapter(),
          new TorusWalletAdapter(),
          //new LedgerWalletAdapter()
      ],
      [network]
  );
  
 
  return (

      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>


                <GameComponent/>


              </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
  );
}

const GameComponent = () => {

  const { connection } = useConnection();
  const { publicKey, sendTransaction, connected , wallet, signTransaction } = useWallet();

  const [ myBalance , setMyBalance ] = useState(0);
  //let myBalance = 0;
  const [ myTokenBalance , setMyTokenBalance ] = useState(0);
  //const [ syBalance , setSyBalance ] = useState(0);
  let syBalance = 0;

  const [ blnReady , setReady ] = useState(false);
  const [ myAccountLink, setMyAccountLink ] = useState("");
  const [ sysAccountLink, setSysAccountLink ] = useState("");

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleMessage = async(ev: MessageEvent<{ type: string; message: string }>) => {
    if (typeof ev.data !== "object") return;
    if (!ev.data.type) return;
    if (ev.data.type == "solBet" && ev.data.message) {
      try{
        //console.log(ev.data.message);          
        const msg = JSON.parse(ev.data.message);        
        //JSON.parse(ev.data.message);        

        //if ( typeof msg["BET"] == 'number') {
          if ( Number.isInteger(msg["BET"]) && msg["BET"] > 0 && msg['BANKPUB'] && Number.isInteger(msg["BET2"]) && msg["BET2"] > 0 && msg['BANKPUB2'] && Number.isInteger(msg["BET3"]) && msg["BET3"] > 0 && msg['BANKPUB3']  && Number.isInteger(msg["BET4"]) && msg["BET4"] > 0 && msg['BANKPUB4']) 
            solBet(msg['BET'],msg['BANKPUB'],msg['BET2'],msg['BANKPUB2'],msg['BET3'],msg['BANKPUB3'],msg['BET4'],msg['BANKPUB4']);          
          else if ( Number.isInteger(msg["BET"]) && msg["BET"] > 0 && msg['BANKPUB'] && Number.isInteger(msg["BET2"]) && msg["BET2"] > 0 && msg['BANKPUB2'] && Number.isInteger(msg["BET3"]) && msg["BET3"] > 0 && msg['BANKPUB3']) 
            solBet(msg['BET'],msg['BANKPUB'],msg['BET2'],msg['BANKPUB2'],msg['BET3'],msg['BANKPUB3']);
          else if ( Number.isInteger(msg["BET"]) && msg["BET"] > 0 && msg['BANKPUB'] && Number.isInteger(msg["BET2"]) && msg["BET2"] > 0 && msg['BANKPUB2']) 
          solBet(msg['BET'],msg['BANKPUB'],msg['BET2'],msg['BANKPUB2']);
          else if ( Number.isInteger(msg["BET"]) && msg["BET"] > 0 && msg['BANKPUB']) 
            solBet(msg['BET'],msg['BANKPUB']);
        //}        
      } catch (error) {
        eval("solBetResult("+false+",'Invalid Bet')");
      }
    }
    if (ev.data.type == "solGetBalance") {
      if (connected && publicKey) {

      }
    }
    if (ev.data.type == "solStatusGet") {
      // tokenid가 넘어오면, 여기서만 체크. 딴데서는 접속되있으면 금액만. : tokenReady 여기서
      if (connected && publicKey) {

        const msg = JSON.parse(ev.data.message);        

        if (msg["TOKENID"] && msg["TOKENID"]!="SOL") {
          const userAT = await getAssociatedTokenAddress( new PublicKey(msg["TOKENID"]), publicKey, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID );
          try {
            userTokenPub = await getAccount(connection, userAT, undefined, TOKEN_PROGRAM_ID);
            gTokenId = msg["TOKENID"];
            strTokenReady = "READY";
          } catch (error:unknown) {
            // NO TOKEN ACCOUNT
            gTokenId = "SOL";
            strTokenReady = "NOACCOUNT";
          }
        } else if (msg["TOKENID"] && msg["TOKENID"]=="SOL") {
          gTokenId = "SOL";
          strTokenReady = "NOTSET";
        }

        connection.getBalance(publicKey).then(res => {
          setMyBalance(res);
          if (strTokenReady=="READY") {
            connection.getTokenAccountBalance(userTokenPub.address).then(res=> {
              setMyTokenBalance(Number.parseInt(res.value.amount));
              solStatusGet(Number.parseInt(res.value.amount));
            });
          } else solStatusGet(); // RETURN WORK?? NO        
        });
      } else {
        solStatusGet(); // RETURN WORK?? NO                
      }
    }
    if (ev.data.type == "solDevAirdrop") solDevAirdrop(); // RETURN WORK?? NO    
  }


  const solgettest = async(tokenid? : string) => {

    if (connected && publicKey) {

      if (tokenid && tokenid!="SOL") {
        const userAT = await getAssociatedTokenAddress( new PublicKey(tokenid), publicKey, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID );
        try {
          userTokenPub = await getAccount(connection, userAT, undefined, TOKEN_PROGRAM_ID);
          gTokenId = tokenid;
          strTokenReady = "READY";
        } catch (error:unknown) {
          // NO TOKEN ACCOUNT
          gTokenId = "SOL";
          strTokenReady = "NOACCOUNT";
        }
      } else if (tokenid && tokenid=="SOL") {
        gTokenId = "SOL";
        strTokenReady = "NOTSET";
      }
      connection.getBalance(publicKey).then(res => {
        setMyBalance(res);
        if (strTokenReady=="READY") {
          connection.getTokenAccountBalance(userTokenPub.address).then(res=> {
            setMyTokenBalance(Number.parseInt(res.value.amount));
            solStatusGet(Number.parseInt(res.value.amount));
          });
        } else {
          solStatusGet(); // RETURN WORK?? NO        
        }
      });
    } else {
      solStatusGet(); // RETURN WORK?? NO                
    }    
  }



  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  });



  const sendMsg = () => {
    if (!iframeRef.current) return;
    const iframeWindow = iframeRef.current.contentWindow;
    if (iframeWindow) {
      iframeWindow.postMessage('hey', '*');
    }    
  }



  useEffect(() => {
    solStatusGet();
  },[blnReady]);

  const solStatusGet = (tokenbal?:number) => {  // DIST
    //if (typeof "solStatusChanged" === 'function') {
      
      if (connected) {
        if (strTokenReady=="READY") {
          if (isDev) console.log("solStatusChanged("+connected+","+blnReady+",'"+publicKey?.toString()+"','"+myBalance+"','" +gTokenId+  "', '" + strTokenReady + "', '"+userTokenPub.address.toString() +"', '"+(tokenbal ? tokenbal : myTokenBalance)+"'  )");
          else eval("solStatusChanged("+connected+","+blnReady+",'"+publicKey?.toString()+"','"+myBalance+"','" +gTokenId+  "', '" + strTokenReady + "', '"+userTokenPub.address.toString() +"', '"+(tokenbal ? tokenbal : myTokenBalance)+"'  )");
        } else {
          if (isDev) console.log("solStatusChanged("+connected+","+blnReady+",'"+publicKey?.toString()+"','"+myBalance+"','" +gTokenId+  "', '" + strTokenReady + "' )");
          else eval("solStatusChanged("+connected+","+blnReady+",'"+publicKey?.toString()+"','"+myBalance+"','" +gTokenId+  "', '" + strTokenReady + "' )");
        }
      }
      else {
        if (isDev) console.log("solStatusChanged("+connected+","+blnReady+")");
        else eval("solStatusChanged("+connected+","+blnReady+")");
      }
    //}
  }

  const solBet = async (bet : number, bankPub : string, bet2? :number, bankPub2? : string, bet3? : number, bankPub3? : string, bet4? : number, bankPub4? : string) => {
    try {
      if (publicKey && blnReady &&  (  gTokenId=="SOL"      ||    gTokenId!="SOL" && strTokenReady=="READY"      )   ) {
        //throw new WalletNotConnectedError();

        setReady(false);
        
        const bankPublicKey = new PublicKey(bankPub);

        const transaction = new Transaction();
        if (gTokenId=="SOL") {
          transaction.add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: bankPublicKey,
                lamports : bet
            })
          );          
          if (bet2 && bankPub2) {
            transaction.add(
              SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(bankPub2),
                lamports : bet2
              })            
            );
          }
          if (bet3 && bankPub3) {
            transaction.add(
              SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(bankPub3),
                lamports : bet3
              })            
            );
          }
          if (bet4 && bankPub4) {
            transaction.add(
              SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(bankPub4),
                lamports : bet4
              })            
            );
          }
            

        } else {


          //const bankAT = await getAssociatedTokenAddress( new PublicKey(gTokenId), bankPublicKey, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID );
          //bankTokenPub = await getAccount(connection, bankAT, undefined, TOKEN_PROGRAM_ID);            
  
          transaction.add(
            createTransferInstruction(
              userTokenPub.address,
              new PublicKey(bankPub),
              publicKey,
              bet,
              [],
              TOKEN_PROGRAM_ID,
            )        
          )

          if (bet2 && bankPub2) {
            transaction.add(
              createTransferInstruction(
                userTokenPub.address,
                new PublicKey(bankPub2),
                publicKey,
                bet2,
                [],
                TOKEN_PROGRAM_ID,
              )        
            )
          }

          if (bet3 && bankPub3) {
            transaction.add(
              createTransferInstruction(
                userTokenPub.address,
                new PublicKey(bankPub3),
                publicKey,
                bet3,
                [],
                TOKEN_PROGRAM_ID,
              )        
            )
          }

          if (bet4 && bankPub4) {
            transaction.add(
              createTransferInstruction(
                userTokenPub.address,
                new PublicKey(bankPub4),
                publicKey,
                bet4,
                [],
                TOKEN_PROGRAM_ID,
              )        
            )
          }          




        }

        const {
          context: { slot: minContextSlot },
          value: { blockhash, lastValidBlockHeight }
        } = await connection.getLatestBlockhashAndContext();


        const signature = await sendTransaction(transaction, connection, { minContextSlot });
        

        

        
        //  https://solscan.io/tx/4X35JFXJ44mwMUmfLigsw9yJyC1oQbYsnxaMP3hGjHgJ4sEWZ7f1K1edvuTqBWvXxuf6p99eZwE9w2hg8mt8QkjQ?cluster=devnet
        // https://solscan.io/account/bbHWmTSUiCtyuKd27okeZyMacRCau1RTeroqdf2d87V?cluster=devnet
        const conres = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature }).then(res =>{
          //connection.getBalance(publicKey).then(setMyBalance);
          //connection.getBalance(systemPublicKey).then(setSyBalance);
          const txUrl = "https://solscan.io/tx/"+signature+"?cluster=devnet";

          // TODO confirm fail???????
          // CLIENT CALL : return work??
          //eval("betResult('"+signature+"')");

          connection.getBalance(publicKey).then(res => {
            setMyBalance(res);

            if (gTokenId!="SOL") {
              connection.getTokenAccountBalance(userTokenPub.address).then(res2=> {
                setMyTokenBalance(Number.parseInt(res2.value.amount));
                setReady(true);
                if (isDev) console.log("solBetResult("+true+",'"+signature+"',"+res+", " + res2.value.amount +  "  )"); // DIST
                else eval("solBetResult("+true+",'"+signature+"',"+res+", " + res2.value.amount +  "  )"); // DIST
              })
            } else {
              setReady(true);
              if (isDev) console.log("solBetResult("+true+",'"+signature+"',"+res+")"); // DIST
              else eval("solBetResult("+true+",'"+signature+"',"+res+")"); // DIST
            }

            
          });
        });

      }
    } catch (error : any ) {
      // CLIENT CALL : return work??
      console.log(error);
      setReady(true);
      if (isDev) console.log("solBetResult("+false+",'"+String(error.message)+"')");  // DIST
      else eval("solBetResult("+false+",'"+String(error.message)+"')");  // DIST

    }
  };

 
/*
  const win = async() => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();

      const transaction = new Transaction().add(
          SystemProgram.transfer({
              fromPubkey: systemPublicKey,
              toPubkey: publicKey,
              lamports: 2000,
          })
      );

      const {
          context: { slot: minContextSlot },
          value: { blockhash, lastValidBlockHeight }
      } = await connection.getLatestBlockhashAndContext();

      sendAndConfirmTransaction(connection, transaction, [ systemKeys[walletName] ]).then(res=>{
        console.log("RECV");
        console.log(res); // tx
        connection.getBalance(publicKey).then(setMyBalance);
        connection.getBalance(systemKeys[walletName].publicKey).then(setSyBalance);        
        setReady(true);
        const txUrl = "https://solscan.io/tx/"+res+"?cluster=devnet";
        toast.success((<div><p>RECEIVE DONE</p><a href={txUrl} target='_blank'>tx url</a></div>));      
      });  
    } catch (error) {
      setReady(true);
      toast.error("Receive Transaction failed");
    }
  }
*/
  // SET INITIAL BALANCE
  useEffect(() => {
    //console.log("CONNECTED:"+connected);
    if (connected && publicKey !== null) {
      // CURRENT WALLET NAME
      //console.log("CURRENT WALLET NAME:"+ wallet?.adapter.name);      
      walletName = ( wallet?.adapter.name != undefined ? wallet?.adapter.name : "" );

      connection.getBalance(publicKey).then(res=> {
        setMyBalance(res);

        setReady(true);
      });
      
      //connection.getBalance(systemPublicKey).then(setSyBalance);
      setMyAccountLink("https://solscan.io/account/"+publicKey+"?cluster=devnet");
      //setSysAccountLink("https://solscan.io/account/"+systemPublicKey+"?cluster=devnet");
      
    } else {
      setReady(false);
    }
    if (!connected) {
      setMyBalance(0);
      syBalance = 0;
      setMyTokenBalance(0);
      strTokenReady = "NOTSET";
      gTokenId = "SOL";
    }
  }, [connected,publicKey]);


  const solDevAirdrop = async() => {
    publicKey && connection.requestAirdrop(publicKey,1000000000).then((id:string) => {
      connection.confirmTransaction(id).then(()=>{
        //connection.getBalance(publicKey).then(setMyBalance);
        alert("AIRDROP DONE");
      })
      
    })
  };

  const testMsg = () => {
    console.log("TESTMSG");
  }

  const bettest = () => {
    solBet(10000,'9TuHVFoi7eD6RSQFZ4LC7UQ8Svrsrqia27cvairj5D7B',40000,'bbHWmTSUiCtyuKd27okeZyMacRCau1RTeroqdf2d87V');
  }

  const soltest1 = () => {
    solgettest('SOL');
  }

  const soltest2 = () => {
    solgettest('Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr');
  }

  //<button onClick={bettest}>BETTEST</button>
  //<button onClick={soltest1}>SOL</button>
  //<button onClick={soltest2}>TOK</button>

  return (
    <div>
      <WalletMultiButton />
    </div>
    
    

  );
};


export default App